@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(tailwind.module.css);

@layer base {
  :root {
    --max-width: 1500px;
    --banner-height: 168px;
    --slate_50: '#F2F3F5';
    --slate_100: '#E2E4E9';
    --slate_200: '#C2C6D1';
    --slate_300: '#A2A8B9';
    --slate_400: '#828AA1';
    --slate_500: '#656D86';
    --slate_600: '#565C72';
    --slate_700: '#464C5D';
    --slate_800: '#373B49';
    --slate_900: '#282B35';
    --slate_1000: '#1D1E22';
  }
}

html,
body,
#__next,
.container-height {
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;
}

html,
body {
  /* padding: 0;
    margin: 0; */
  font-family: 'Inter', sans-serif !important;
  background-color: #373b49;
  overflow-y: clip;
  overflow-x: clip;
  /* border-color: '#E2E4E9'; */
  /* display: table;
    width: 100%; */
  /* min-height: calc(100% + env(safe-area-inset-top)); */
  /* padding: calc(env(safe-area-inset-top)/2) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
  /* padding-bottom: env(safe-area-inset-bottom); */
}
#__next {
  background-color: #f2f3f5;
}

[class^='css-text'] {
  font-family: 'Inter', sans-serif !important;
}

a {
  color: #065de0;
  background-color: transparent;
  text-decoration: underline;
}

.typewriter {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

/* The shake horizontal effect */
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  12.5%,
  37.5%,
  62.5%,
  87.5% {
    transform: translateX(5px);
  }
  25%,
  50%,
  75% {
    transform: translateX(-5px);
  }
}

.shake-horizontal {
  animation: shake-horizontal 0.5s ease;
  animation-delay: 0.8s;
}

/* calendar override container styles */
.DayPicker {
  border: 0;
  box-shadow: none;
}
/* calendar set fixed height */
.CalendarMonth,
.DayPicker_transitionContainer {
  height: 330px !important;
}

/** 
CALENDAR DAY CSS OVERRIDE 
*/
/* calendar day hover */
.CalendarDay:hover,
.CalendarDay__today:hover {
  background-color: #065de0 !important;
  color: white !important;
  border: 0;
  border-radius: 8px;
}
/* calendat day selected for single date picker only */
.CalendarDay.CalendarDay__default.CalendarDay__selected:not(.CalendarDay__selected_start, .CalendarDay__selected_end) {
  border-radius: 8px;
}
/* calendar day hover when only start is provided */
.CalendarDay.CalendarDay__selected_start.CalendarDay__defaultCursor:not(.CalendarDay__selected_end):hover {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* calendar day hover that is selected (for start) and not default cursor (initial/first select) */
.CalendarDay.CalendarDay__selected_start:not(.CalendarDay__defaultCursor):hover {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* calendar day hover that is selected (for end) and not default cursor (initial/first select) */
.CalendarDay.CalendarDay__selected_end:not(.CalendarDay__defaultCursor):hover {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/* calendar day hover between start and end */
.CalendarDay:hover.CalendarDay__selected_span {
  border-radius: 0;
}
/* calendar day initial select */
.CalendarDay__defaultCursor:not(.CalendarDay__selected_start) {
  border-radius: 8px;
}
/* calendar day between selected start and end */
.CalendarDay__selected_span {
  background-color: #e2e4e9;
  color: #484848;
  border-radius: 0 !important;
}
/* calendar day selected as start */
.CalendarDay__selected_start {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
/* calendar day selected as end */
.CalendarDay__selected_end {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* calendar day selected but not an outside day */
.CalendarDay__selected:not(.CalendarDay__outside) {
  background-color: #065de0;
}
/* calendar day today */
.CalendarDay__today:not(.CalendarDay__selected, .CalendarDay__selected_span) {
  background-color: #f2f3f5;
  border-radius: 8px;
}

/** 
CALENDAR OUTSIDE DAYS CSS OVERRIDE 
*/
/* calendar day that is outside the month */
.CalendarDay__outside {
  pointer-events: none;
  background-color: none !important;
  color: #dddddd;
}
/* calendar day hover that is outside the month */
.CalendarDay__outside:hover {
  cursor: default !important;
}
/* calendar day selected but outside the current month */
.CalendarDay__selected.CalendarDay__outside,
.CalendarDay__selected_span.CalendarDay__outside {
  background-color: white;
  color: #dddddd;
}

.DayPicker {
  overflow: hidden;
  background-color: white;
  border-radius: 20px;
}

.DateInput_screenReaderMessage {
  display: none;
}

.CalendarDay {
  text-align: center;
  border: none;
}

.DateInput_input {
  font-size: 14px;
  padding: 0;
  padding: 4px 0;
  border-bottom: none;
  color: #464c5d;
  --placeholderTextColor: #656d86;
}

.DateRangePickerInput {
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.DateRangePickerInput__withBorder {
  border: none;
}
.DateRangePicker {
  box-sizing: border-box;
  height: 32px;
  width: 100%;
  padding: 0 6px;
  border-radius: 6px;
  outline: 1px solid #e2e4e9;
}
.DateRangePickerInput_arrow {
  padding: 0px 6px;
}

@media only screen and (max-width: 480px) {
  .react-toastify-custom-class {
    bottom: 10px;
    width: 95vw;
    margin: 0 2.5vw;
  }
}

::placeholder {
  color: #656d86 !important;
}
