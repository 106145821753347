.main-container {
  max-width: 1500px;
}

/* Dialog box modal */
.dialog-container {
  border-radius: 6px;
  background-color: white;
}
.dialog-content {
  @apply overflow-y-auto;
  padding: 12px 24px;
}
@media screen and (min-height: 0px) {
  .dialog-content {
    max-height: 50vh;
  }
}
@media screen and (min-height: 700px) {
  .dialog-content {
    max-height: 60vh;
  }
}
.dialog-header {
  padding: 16px 24px;
}
.dialog-header button {
  border: 1px solid theme('colors.slate-100');
}

.dialog-footer {
  padding: 12px 24px;
}

/* Button */
.button-primary {
  @apply md:w-11 md:h-12;
  background-color: theme('colors.mono-white') !important;
  width: 30px !important;
  width: 30px !important;
}

/* Card */
.content-container {
  @apply sm:py-[24px] sm:px-[24px];
}
.content-card {
  @apply md:p-10 xxs:p-5 rounded-[60px];
  border-radius: 12px !important;
}
.widget-content {
  @apply xxs:px-4 xxs:py-2 rounded-[60px];
  border-radius: 12px !important;
}

/* Banner */
.page-banner {
  @apply md:mx-[48px] lg:mx-[96px] xxs:mx-5  bg-slate-900;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: left;
}
@media screen and (max-width: 600px) {
  .page-banner {
    background-image: url('/pickstar-works-banner-mobile.png');
  }
}

.common-border {
  border: 1px solid theme('colors.slate-100') !important;
}

.paper-border {
  @apply common-border;
  border-radius: 6px;
}

.mobile-directory-paper {
  box-shadow: none;
  border: 1px solid theme('colors.slate-100');
  border-radius: 6px;
  justify-content: space-between;
  padding: 10px;
}

.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
