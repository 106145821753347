/** styles overwrite */

.rbc-month-view {
  border-radius: 6px;
  background-color: white;
}
/** month header */
.rbc-header {
  padding: 6px 12px;
  text-align: right;
}

.rbc-header span {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.14px;
}
.rbc-month-header .rbc-header {
  border: none;
}

.rbc-show-more {
  width: 100%;
  bottom: 0;
}

.rbc-month-row .rbc-row-content {
  height: 100%;
}

.rbc-event {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.rbc-month-header .rbc-header {
  border-bottom: 1px solid #e2e4e9;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #e2e4e9;
}
.rbc-day-bg {
  background-color: transparent !important;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #e2e4e9;
}
.rbc-today {
  background-color: #d7dde9 !important;
}

.rbc-date-cell {
  padding: 8px 12px;
  letter-spacing: 1.2px;
  font-size: 12px;
  font-weight: 600;
  color: #464c5d;
}
.rbc-date-cell.rbc-now {
  font-weight: 700;
}
.rbc-date-cell.rbc-off-range {
  color: #828aa1;
}
.rbc-row-segment {
  padding: 0 12px;
}
.rbc-show-more {
  margin-top: 8px;
}
.rbc-show-more > div {
  padding: 0 !important;
}
.rbc-show-more,
.rbc-show-more div {
  background-color: transparent !important;
}
